<template>
  <div>
    <section
      class="bg-[url('https://assets-cdn.wetix.co/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/c7c55d4d-55dd-4dfc-8eb8-0e6a68dd5200/bg1080blur')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60 h-screen"
    >
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen pt:mt-0">
        <div v-motion-fade class="flex items-center mb-6 text-2xl font-semibold text-white">
          <img class="h-8 opacity-50 transition-colors" src="~/assets/wetix-logo-white.svg" alt="WeTix">
        </div>
        <div
          v-if="loginVisibleThrottled"
          v-motion
          :initial="{
            scale: 0,
          }"
          :enter="{
            scale: 1,
            transition: {
              delay: 1000,
            },
          }"
          class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 "
        >
          <UCard class="">
            <div class="space-y-2">
              <UButton block size="lg" :loading="loginWithGoogleLoading" variant="outline" @click="doLoginWithGooglePopup">
                <template #leading>
                  <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17 8.18371C17 7.63989 16.9551 7.09314 16.8591 6.55814H9.16046V9.63879H13.5691C13.3862 10.6324 12.7983 11.5113 11.9376 12.0698V14.0687H14.5678C16.1123 12.6754 17 10.6177 17 8.18371Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M9.16042 16C11.3617 16 13.2182 15.2916 14.5707 14.0687L11.9406 12.0698C11.2088 12.5578 10.2641 12.8341 9.16342 12.8341C7.03409 12.8341 5.22865 11.4261 4.58085 9.53299H1.8667V11.5936C3.25227 14.2951 6.07438 16 9.16042 16V16Z"
                      fill="#34A853"
                    />
                    <path
                      d="M4.57786 9.53298C4.23596 8.53941 4.23596 7.46353 4.57786 6.46996V4.40933H1.8667C0.709065 6.66985 0.709065 9.33309 1.8667 11.5936L4.57786 9.53298V9.53298Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M9.16042 3.16589C10.3241 3.14825 11.4487 3.57743 12.2914 4.36523L14.6217 2.0812C13.1462 0.72312 11.1878 -0.0235267 9.16042 -1.02057e-05C6.07438 -1.02057e-05 3.25227 1.70493 1.8667 4.40932L4.57785 6.46995C5.22265 4.57394 7.03109 3.16589 9.16042 3.16589V3.16589Z"
                      fill="#EA4335"
                    />
                  </svg>
                </template>
                Sign in with Google
              </UButton>
              <UButton
                block
                size="lg"
                :loading="loginWithGoogleLoading"
                icon="i-mdi-google"
                variant="outline"
                @click="doLoginWithWetix"
              >
                <template #leading>
                  <Icon name="wetix" />
                </template>
                Sign in with WeTix
              </UButton>
            </div>

          <!-- <pre>{{ user }}</pre> -->
          <!-- <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                email</label>
              <input
                id="email"
                type="email"
                name="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="name@company.com"
              >
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Password</label>
              <input
                id="confirm-password"
                type="password"
                name="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              >
            </div>
            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  >
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div>
              <a
                href="#"
                class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >Forgot
                password?</a>
            </div>
            <button
              type="submit"
              class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Log
              in to your account
            </button>
            <p class="text-sm font-light text-center text-gray-500 dark:text-gray-300">
              <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Don't
                have an account?</a>
            </p>
          </form> -->
          </UCard>
        </div>
      </div>
    </section>
    <div
      v-if="devModeActive"
      class="bg-gray-50 dark:bg-gray-800"
    >
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto pt:mt-0">
        <UCard
          class="mt-4"
        >
          <div>
            <div class="text-md font-bold mb-4">
              Public Config
            </div>
            <pre class="text-xs">
          {{ useRuntimeConfig().public }}
            </pre>
          </div>
        </UCard>
        <UCard
          class="mt-4"
        >
          <div>
            <div class="text-md font-bold mb-4">
              User
            </div>
            <pre class="text-xs">
          {{ user }}
            </pre>
          </div>
        </UCard>
      </div>
    </div>
    <LayoutFooterAppGeneral />
  </div>
</template>

<script setup lang="ts">
import {
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth'
import { useCurrentUser, useFirebaseAuth } from 'vuefire'

// Utils
const route = useRoute()
const auth = useFirebaseAuth()
const devModeActive = useDevModeActive()
const runtimeConfig = useRuntimeConfig()

// State
const loginVisible = ref(false)
const user = useCurrentUser()
const loginWithGoogleLoading = ref(false)
const loginWithWetixLoading = ref(false)
const loginVisibleThrottled = refThrottled(loginVisible, 3000)

// we don't need this watcher on server
onMounted(() => {
  watch(user, (user, prevUser) => {
    if (prevUser && !user) {
      // user logged out
      navigateTo('/login')
    } else if (user && typeof route.query.redirect === 'string') {
      // user logged in
      navigateTo(route.query.redirect)
    } else if (user) {
      // user logged in
      navigateTo('/app/tenant-admin')
    }
  })
})

// Delay showing the login form to prevent flickering on page load
useDelay(() => { loginVisible.value = true }, 1000) // useDelay comes from lodash

const doLoginWithGooglePopup = async () => {
  try {
    loginWithGoogleLoading.value = true
    const result = await signInWithPopup(auth, new GoogleAuthProvider())
    console.log('signInWithPopup result', result)
    if (result.user && result.user.displayName) {
      useToast().add({
        id: 'login-success-notification',
        title: 'Logged in',
        description: `Welcome back ${result.user.displayName}`,
        icon: 'i-heroicons-check-circle'
      })
    }
    loginWithGoogleLoading.value = false
  } catch (error) {
    loginWithGoogleLoading.value = false
    useToast().add({
      id: 'login-fail-notification',
      title: 'Error logging in',
      description: `${error.message}`,
      icon: 'i-heroicons-exclamation-circle',
      color: 'red'
    })
    throw createError(error)
  }
}

const doLoginWithWetix = () => {
  try {
    loginWithWetixLoading.value = true
    const loginUrl = `${runtimeConfig.public.tixx?.authBaseUrl}/login?redirect=${encodeURIComponent(window.location.href)}`
    navigateTo(loginUrl, { external: true })
    loginWithWetixLoading.value = false
  } catch (error) {
    loginWithGoogleLoading.value = false
    useToast().add({
      id: 'login-fail-notification',
      title: 'Error logging in',
      description: `${error.message}`,
      icon: 'i-heroicons-exclamation-circle',
      color: 'red'
    })
    throw createError(error)
  }
}

</script>
